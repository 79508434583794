<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container synopsis">
            <!-- 头部banner -->
            <div class="section_banner_container">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <h1 class="section_banner_heading">关于我们</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 内容 -->
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-sm-10 col-sm-offset-1">
                  <article
                    id="post-65"
                    class="post-65 page type-page status-publish hentry"
                  >
                    <div class="entry-content">
                      <div class="vc_row wpb_row vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-12"
                        >
                          <div class="vc_column-inner">
                            <div class="wpb_wrapper">
                              <div class="outline">
                                <h3>集团简介</h3>
                                <div class="outline_content">
                                  <div class="text">
                                    盈喜集团于2000年进入纺织服装领域，历经20年的发展，盈喜集团现已成为纺织服装行业内专注于产业链服务的综合性集团公司，业务范围遍及全球。
                                  </div>
                                  <div class="text">
                                    2016年，盈喜集团通过反向并购的方式，成功登陆美国资本市场。作为中国纺织服装行业产业链的探索者、实践者和引领者，盈喜集团秉承资源配置最优化、资源效益最大化的基本思想，从供给侧为全球纺织服装企业提供专业化、系统化服务，促进纺织服装企业改革并转型升级，为纺织服装企业发展提供持续动力。
                                  </div>
                                </div>

                                <div class="scan-code">
                                  <div class="img-d">
                                    <img
                                      src="../../../public/components/qrCode.png"
                                    />
                                  </div>
                                  <span>微信公众号</span>
                                </div>
                                <h3>公司大事记及重要里程碑</h3>
                                <div class="Journalism">
                                  <ul>
                                    <li>
                                      <div class="Journalism-left">
                                        2000年08月
                                      </div>
                                      <h4>
                                        <i></i>
                                        <span>盈喜集团进入纺织服装领域</span>
                                      </h4>
                                    </li>
                                    <li>
                                      <div class="Journalism-left">
                                        2016年10月
                                      </div>
                                      <h4>
                                        <i></i>
                                        <span
                                          >盈喜集团成功登陆美国证券市场</span
                                        >
                                      </h4>
                                    </li>
                                    <li>
                                      <div class="Journalism-left">
                                        2018年10月
                                      </div>
                                      <h4>
                                        <i></i>
                                        <span
                                          >盈喜集团股份有限公司入驻京基100大厦</span
                                        >
                                      </h4>
                                    </li>
                                    <li>
                                      <div class="Journalism-left">
                                        2020年06月
                                      </div>
                                      <h4>
                                        <i></i>
                                        <span
                                          >盈喜集团与新乡市政府签署服装产业园合作协议</span
                                        >
                                      </h4>
                                    </li>

                                    <li>
                                      <div class="Journalism-left">
                                        2022年08月
                                      </div>
                                      <h4>
                                        <i></i>
                                        <span
                                        >盈喜集团正式在美国纳斯达克主板上市交易，股票交易代码：ATXG</span
                                        >
                                      </h4>
                                    </li>
                                  </ul>
                                </div>

                                <h3>集团子公司</h3>
                                <div class="Business-and-products">
                                  <h4 class="subsidiary">
                                    一、东莞市泓翔商业有限公司
                                  </h4>
                                  <div class="text">
                                    东莞市泓翔商业有限公司于2020年注册成立，是东莞虎门泓翔电商产业中心的运营主体公司。泓翔电商产业中心占地面积3万多平方米，总营业面积约6万平方米，现已成为集电商供货、网红直播及电商办公、餐饮休闲等于一体的大型电商产业中心，汇集了东莞虎门核心服装电商供应链优质企业，现有入驻企业和服装供货商数百家。
                                  </div>
                                  <h4 class="subsidiary">
                                    二、深圳市盈喜鹏发物流有限公司
                                  </h4>
                                  <div class="text">
                                    深圳市盈喜鹏发物流有限公司，最早成立于1996年，是一家具有二十年运输经验的专线物流公司，专业承接深圳往返广东、福建各地运输业务以及仓储分拣配送服务。公司一直秉承着“厚德载物，安心托付”的企业精神，坚持安全、快捷、经济、高效的服务理念，建立了电商配送、货物运输、信息跟踪、仓储管理四大服务体系，通过严谨的服务流程，统一的服务标准保障了货物安全、快捷、及时到达，并以最高效的营运管理为客户提供一站式的专业物流服务。
                                  </div>
                                  <h4 class="subsidiary">
                                    三、深圳市新快捷运输有限公司
                                  </h4>
                                  <div class="text">
                                    深圳市新快捷运输有限公司是一家专业平板运输、集装箱运输、报关一条龙服务的运输企业，是深圳市集装箱拖车运输协会的会员单位。公司于2001年经深圳市工商局、交通局比准成立，注册资本1000万元人民币。公司总部设在盐田，在南山蛇口设有分公司，主要承接全国长途运输、进出口运输、散货入仓及报关等业务。
                                  </div>
                                  <h4 class="subsidiary">
                                    四、汕头市佰伊服饰有限公司
                                  </h4>
                                  <div class="text">
                                    汕头市依佰伊服饰有限公司，其前身汕头市澄海埭头服装工艺厂，正式成立于1982年，是粤东地区最早的中外合作制衣企业之一。公司从事时装生产20余年，技术力量雄厚，生产经验丰富，有较强的服装设计和生产能力，主要产品包括梭织类服装、针织类服装、牛仔、童装、男装、浴袍、睡袍、绣花及钉珠类服装等。
                                  </div>
                                  <h4 class="subsidiary">
                                    五、东莞市恒圣威服饰有限公司
                                  </h4>
                                  <div class="text">
                                    东莞市恒圣威服饰有限公司创建于2009年，公司座落于东莞市横沥镇横沥村综合开发区，是一家专业生产高档梳织时装，生产车间拥有现代化的生产设备及优美的生活环境。公司货源充足。主要产品有茄克、大衣、棉褛、休闲裤、T恤等男女装休闲服系列。
                                  </div>
                                  <h4 class="subsidiary">
                                    六、东莞誉尚服饰有限公司
                                  </h4>
                                  <div class="text">
                                    东莞誉尚服饰有限公司，最早创建于2009年，公司座落于东莞市茶山镇马鞍路22号2号楼4楼，由深圳市前海盈喜产业链服务有限公司（盈喜集团）投资的一家专业从事服装研发、生产及销售的公司是，主要产品有茄克、大衣、棉褛、休闲裤、 T恤等男女装休闲服系列。在服装细节设计、面料选择、风格塑造上都以优雅、时尚为主打，并通过多种工艺，如立体花饰、印花，手工绣花等不同手法和质感使风格变得更加优雅高尚和品位。
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- .entry-content -->
                  </article>
                  <!-- #post-65 -->
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <Footers></Footers>
  </div>
</template>
<script>
import Headers from "./../../components/header/header.vue";
import Footers from "./../../components/footer/footer.vue";
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
export default {
  components: { Headers, Footers },
  metaInfo: {
    title: "盈喜集团企业简介_关于盈喜 - 盈喜集团股份有限公司|ATXG",
    meta: [
      { name: "keywords", content: "盈喜集团简介，盈喜股份介绍，ATXG" },
      {
        name: "description",
        content:
          "盈喜集团于2000年进入纺织服装领域，现已成为纺织服装行业内专注于产业链服务、业务覆盖全球的综合性集团公司。2016年成功登陆美国证券市场，目前正积极转板至纳斯达克上市。",
      },
    ],
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.synopsis {
  text-align: left;
  .subsidiary {
    font-weight: bold;
  }
  .section_banner {
    background-image: url(../../../public/home/img/bg-section-banner-contact.jpg);
  }
  .outline h3 {
    font-family: "微软雅黑";
    font-size: 24px;
    color: #333333;
    letter-spacing: 0;
    line-height: 50px;
    padding: 0;
    margin: 0;
    font-weight: 700;
    // border-bottom: 1px solid #cccccc;
  }
  .outline .outline_content {
    padding: 32px 0;
  }
  .outline .outline_content .text {
    font-family: "微软雅黑";
    font-size: 16px;
    color: #585858;
    letter-spacing: 0;
    text-align: justify;
    line-height: 30px;
    margin-bottom: 8px;
  }
  .scan-code {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    img {
      max-width: 178px;
      max-height: 178px;
      width: auto;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }
  /*Journalism*/
  .Journalism {
    margin-bottom: 100px;
    margin-top: 20px;
  }
  .Journalism ul li {
    display: flex;
    width: 100%;
    height: 50px;
    line-height: 50px;
    align-items: center;
  }
  .Journalism ul li .Journalism-left {
    font-size: 16px;
    color: #585858;
    display: block;
    flex: 0 auto;
    width: 90px;
  }
  .Journalism ul li h4 {
    flex: 1;
    padding: 0;
    margin: 0;
  }
  .Journalism ul li h4 i {
    margin-left: 16px;
    margin-right: 16px;
    display: inline-block;
    border-radius: 50%;
    width: 3px;
    height: 3px;
    background: #ffffff;
    border: 3px solid #a33128;
  }
  .Journalism ul li h4 span {
    font-weight: initial;
    font-size: 16px;
    color: #585858;
    letter-spacing: 0;
    line-height: 24px;
  }
  /*Business-and-products*/
  .Business-and-products {
    padding-top: 32px;
    margin-bottom: 100px;
  }
  .Business-and-products h4 {
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
    color: #333333;
    font-size: 17px;
    padding-top: 20px;
  }
  .Business-and-products .text {
    margin-bottom: 8px;
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
    line-height: 30px;
  }
}
</style>
